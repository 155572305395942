//Bootstrap breakpoints custom adjustments before loading in bootstrap
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px,
);

/* You can add global styles to this file, and also import other style files */
@import "/node_modules/bootstrap/scss/bootstrap-grid.scss";
@import "assets/scss/custom/palette-streapp";
@import "assets/scss/custom/mat-streapp";
@import "assets/scss/custom/elements-streapp";

//Fix for migration from bootstrap 4 to 5
*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
}

//this makes it possible to use a direct aspect ratio param in the inline style on an element
//i.e. a div which contains a 16 by 9 image has the inline style: --aspect-ratio: 16/9;
[style*="--aspect-ratio"] > :first-child {
  width: 100%;
}

[style*="--aspect-ratio"] > img {
  height: auto;
}

@supports (--custom: property) {
  [style*="--aspect-ratio"] {
    position: relative;
  }

  [style*="--aspect-ratio"]::before {
    content: "";
    display: block;
    padding-bottom: calc(100% / (var(--aspect-ratio)));
  }

  [style*="--aspect-ratio"] > :first-child {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }
}

//Edge hack
@supports (-ms-ime-align: auto) {
  .sixteenByNine {
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
  }

  .oneByOne {
    width: 100%;
    padding-top: 100%; /* 1:1 Aspect Ratio */
  }
}

.content {
  min-height: calc(100vh - 320px);
}

.text-right {
  text-align: right;
}

.bold {
  font-weight: bold !important;
}

.no-padding {
  padding-left: 0;
  padding-right: 0;
}

.no-border {
  border: none !important;
}

.no-scrolling {
  overflow: hidden;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  height: 100vh;
}

.component-container > div {
  width: auto;
  position: relative;
  display: contents;
}

.mat-card-actions {
  color: $zwart-300;
  position: absolute;
  bottom: 0;
  margin-bottom: 0 !important;
  padding: 6px 0 !important;
  background: $brand-secondary;
  z-index: 2;
  height: 48px;

  .mat-button {
    float: right;
  }

  .mat-icon {
    margin-top: -6px;
  }
}

// Widget Content
.widget {
  .mat-card-settings {
    .mat-form-field {
      width: 100% !important;
    }
  }

  .mat-card-help,
  .mat-card-settings {
    padding: 0 16px;
  }

  .mat-card-actions {
    color: $zwart-300;
    position: absolute;
    bottom: 0;
    margin-bottom: 0 !important;
    padding: 6px 10px !important;
    background: $brand-secondary;
    z-index: 2;
    height: 48px;
    left: 0px;
    width: 100%;
    right: 0px;
    margin: 0px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;

    .mat-button {
      float: right;
    }

    .mat-icon {
      margin-top: -6px;
    }
  }

  .share-btn {
    .mat-icon {
      margin: 0;
    }
  }
}

.widgetCard {
  height: 100%;
  position: relative;
}

ngx-gridster {
  .gridster-item-inner {
    height: 100%;
  }

  .widget {
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden;
    position: absolute !important;
    width: 100% !important;
    height: 100% !important;
    border-radius: 0px;
    padding: 0px 0px 50px;

    .mat-card-content.padding {
      padding: 0px 16px;
    }

    ul {
      margin: 0;
      padding: 0;
    }

    .article-list {
      width: 100%;
      position: relative;
      padding: 5px 0px 4px;

      &:first-child {
        padding: 4px 0 5px;
      }

      &:after {
        display: block;
        content: "";
        height: 1px;
        background-color: $zwart-50;
        position: relative;
        left: 85px;
        bottom: -4px;
        width: 100%;
      }
    }

    &.widget-rtic,
    &.widget-gi {
      .article-list {
        &:after {
          left: 70px;
        }
      }
    }

    &.widget-bluefocus,
    &.widget-basisteam {
      .article-list {
        &:after {
          left: 140px;
        }
      }
    }

    &.widget-bluefocus {
      background-color: $zwart-5;

      .mat-card-actions {
        background-color: $zwart-5;
      }
    }
  }
  // NOTE: the overflow and z-index settings fix the scrolling issue when dragging a widget out of bounds
  // this does however create some jitter when dragging a widget to the edge of the screen
  .gridster-container {
    .display-grid {
      overflow: visible;

      .editMode {
        .widgetCard:before {
          content: "";
          display: block;
          position: absolute;
          height: 100%;
          width: 100%;
          z-index: 10;
          background-color: rgba(255, 255, 255, 0.51);
        }

        .gridster-item-resizable-handler {
          z-index: 11;
        }
      }

      .editMode.gridster-item-moving {
        z-index: 9999;
      }
    }
  }
}

.article-list {
  cursor: pointer;
}

@media only screen and (max-width: 767px) {
  .content {
    min-height: calc(100vh - 236px);
  }
}

.reorder-mode {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12) !important;
  border: none !important;

  .mat-toolbar {
    opacity: 0.5;
  }
}

@media screen and (max-width: 1059px) and (min-width: 758px) {
  .mat-card > .mat-card-actions:last-child {
    button {
      padding: 0 4px;
      margin: 0 4px;

      &:first-child {
        margin: 0 4px 0 0;
      }
    }
  }
}

.card-button-right-aligned {
  float: right;
  line-height: 0;
}

.errorText {
  margin-top: 60px !important;
  margin-bottom: 100px !important;
  z-index: 20;
  text-align: center;
  width: 100%;
  padding: 0 10px;
  color: $zwart-300;
}

.gridcard-mobile {
  margin: 0 -5px -50px -5px;
  width: 100vw;
  background-color: $white;

  ul {
    -webkit-padding-start: 0;
    -webkit-margin-after: -1em;

    .article-list {
      width: 100%;
      position: relative;
      padding: 5px 0px;

      &:first-child {
        padding: 0 0 5px;
      }

      &:after {
        display: block;
        content: "";
        height: 1px;
        background-color: $zwart-50;
        position: relative;
        left: 85px;
        bottom: -5px;
        width: 100%;
      }
    }
  }

  &.bluetube {
    .article-list {
      padding: 0 0 5px;

      &:after {
        left: 0;
      }
    }
  }

  &.bluefocus {
    .article-list:after {
      left: 140px;
    }
  }

  ul:empty {
    margin-top: 25px;
  }
}

.list-action-ripple {
  width: 100%;
  height: 100%;
  position: absolute;
}

.inline-indicator-digit {
  float: right;
  height: 24px;
  line-height: 24px;
  padding-left: 10px;
}

.inline-indicator-icon {
  float: left;
}

.inline-indicator-multiple {
  margin-left: 10px;
}

.ng2-carouselamos-container {
  display: flex;
  justify-content: flex-start !important;
}

.ng2-carouselamos-wrapper {
  width: 91% !important;
}

.lightbox .mat-dialog-container {
  background: transparent;
  border: 0 none;
  box-shadow: none;
}

.cdk-overlay-container .medium {
  .mat-mdc-dialog-container {
    max-width: 800px !important;

    .dialog {
      max-width: 752px !important;    
    }
  }
}

//Old Dialog
.cdk-overlay-container {
  .mat-dialog-container {
    display: flex;
    padding: 0px;
    min-width: 500px;
    max-width: 500px;
    min-height: 500px;

    .dialog {
      margin: 24px;
      min-width: 452px;
      min-height: 452px;
      display: flex;
      flex-direction: column;
      max-width: 452px;

      .mat-dialog-content {
        flex-grow: 1;
      }

      .header {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-top: -24px;
        margin-bottom: 10px;
        padding-left: 24px;
        background-color: map-get($mat-streappblauw, 500);
        color: $white;
        text-align: center;
        line-height: 32px;
        display: flex;
        align-items: center;

        .title {
          flex-grow: 1;
        }
      }

      &.promo {
        .header {
          font-weight: bold;
          line-height: 35px;
          background-color: map-get($green, 300);
        }
      }
    }
  }
}

//New Dialog
.cdk-overlay-container {
  .mat-mdc-dialog-container {
    display: flex;
    padding: 0px;
    min-width: 500px;
    max-width: 500px;
    min-height: 500px;
    --mdc-dialog-container-shape: 10px;

    .dialog {
      margin: 24px;
      min-width: 452px;
      min-height: 452px;
      display: flex;
      flex-direction: column;
      max-width: 452px;

      .mat-dialog-content {
        flex-grow: 1;
      }

      .header {
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-top: -24px;
        margin-bottom: 10px;
        background-color: map-get($mat-streappblauw, 500);
        color: $white;
        text-align: center;
        line-height: 32px;
        display: flex;
        align-items: center;

        .title {
          flex-grow: 1;
          padding: 10px 0px;
          font-size: 24px;
        }

        .close-ref {
          position: absolute;
          right: 15px;
        }
      }

      .close-ref {
        font-weight: bold;
      }

      .dialog-buttons {
        display: flex;
        justify-content: space-between;
      }

      .dialog-button-center {
        display: flex;
        justify-content: center;
      }

      .dialog-button-end { // Single button
        display: flex;
        justify-content: flex-end;
      }

      .dialog-buttons-end { // 2 buttons
        display: flex;
        justify-content: flex-end;
        
        .cancel {
          color: $black;
          background-color: inherit;
          margin-right: 15px;
        }
        
        .confirm:disabled {
            color: $white;
        }
      }

      &.promo {
        .header {
          font-weight: bold;
          line-height: 35px;
          background-color: map-get($green, 300);
        }
      }
    }
  }
}

.overviewpage,
.detailpage {
  padding-bottom: 20px;

  &.bg-secondary {
    min-height: calc(100vh - 245px);
  }
}

.breadcrumb-container {
  margin-bottom: 20px;
  margin-top: 20px;
}

.breadcrumb-flatbottom {
  margin-bottom: 0;
  padding-bottom: 0;
}

.overview.icon-help {
  float: right;
  cursor: pointer;
}

ngx-gridster {
  .position-highlight {
    background-color: rgba(0, 0, 0, 0.1);
  }

  ngx-gridster-item {
    .gridster-item-inner {
      top: 10px;
      bottom: 10px;
      left: 10px;
      right: 10px;
      position: absolute;
      height: auto;
      width: auto;
      border-radius: 3px;

      .more-content-indicator {
        display: none;
      }
    }
  }

  ngx-gridster-item.editMode {
    cursor: move;

    .gridster-item-resizable-handler {
      z-index: 11;
    }

    .gridster-item-inner:before {
      content: "";
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      z-index: 10;
      background-color: rgba(255, 255, 255, 0.51);
    }

    .gridster-item-inner {
      .more-content-indicator {
        display: block;
        background: -moz-radial-gradient(
          center,
          ellipse cover,
          rgba(0, 70, 130, 0.4) 0%,
          rgba(0, 70, 130, 0) 58%,
          rgba(0, 70, 130, 0) 100%
        ); /* FF3.6-15 */
        background: -webkit-radial-gradient(
          center,
          ellipse cover,
          rgba(0, 70, 130, 0.4) 0%,
          rgba(0, 70, 130, 0) 58%,
          rgba(0, 70, 130, 0) 100%
        ); /* Chrome10-25,Safari5.1-6 */
        background: radial-gradient(
          ellipse at center,
          rgba(0, 70, 130, 0.4) 0%,
          rgba(0, 70, 130, 0) 58%,
          rgba(0, 70, 130, 0) 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        background-position: 0 30px;
        background-repeat: no-repeat;
        background-size: 100%;
      }

      .half-item-block {
        display: none;
      }
    }

    .gridster-item-inner:hover:before {
      background-color: rgba(223, 223, 223, 0.51);
    }
  }

  ngx-gridster-item.is-dragging {
    .gridster-item-inner {
      -webkit-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
      -moz-box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
      box-shadow: 5px 5px 5px 0 rgba(0, 0, 0, 0.3);
    }
  }
}

@media only screen and (max-width: 767px) {
  ngx-gridster {
    ngx-gridster-item {
      width: 100% !important;

      .gridster-item-inner {
        left: 0;
        right: 0;
      }
    }

    ngx-gridster-item.editMode {
      .gridster-item-inner {
        left: 10px;
        right: 10px;
      }
    }
  }

  .cdk-overlay-container {
    .mat-dialog-container, .mat-mdc-dialog-container {
      min-width: 100%;
      max-width: 100%;

      .dialog {
        margin: 24px;
        min-width: 225px;
        min-height: calc(100% - 52px);

        .mat-dialog-content {
          flex-grow: 1;
          max-height: 100vh;
        }
      }
    }
  }
}

//override the default blue color of mat dropdown indicators
.bfh-expansionpanel {
  .mat-expansion-panel-header-description,
  .mat-expansion-indicator::after {
    color: $white;
  }
}

@keyframes puff {
  100% {
    background-position: 0 -160px;
  }
}

// Overview page card grid styling
.overview-grid {
  .description {
    margin-bottom: 20px;
    min-height: 40px;
  }

  .mat- {
    border-radius: 0;
  }

  .article-card-content {
    display: flex;
    flex-wrap: wrap;
    min-height: 110px;
  }

  .mat-card-title {
    width: 100%;
    max-height: 60px;
  }

  .prioHeading {
    width: 100%;
  }

  p {
    align-self: flex-end;
    width: 100%;
  }
}

@media (min-width: 1381px) {
  .overview-grid,
  .placeholder-item {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.mat-tab-body .mat-tab-body-content {
  height: auto;
}

@media print {
  .video-overlay {
    display: none;
  }

  .mat-drawer-container {
    padding: 0;
    background-color: $white !important;
  }

  .mat-card,
  .mat-card-content {
    border: 0 !important;
    box-shadow: none !important;
    color: $zwart-1000 !important;
    font-size: 11pt;
    padding: 0 !important;
    margin: 0 !important;
  }

  .navbar,
  .footer,
  .title,
  .mh-indicators,
  .breadcrumb-container,
  .mat-card-actions,
  .add-reaction,
  .mat-list-imageblock,
  .mat-list-iconblock,
  .infinite-end,
  .items,
  .media-block,
  .scroll-button {
    display: none !important;
  }

  img {
    page-break-before: always;
    break-inside: avoid;
    display: block;
  }

  .media-header {
    -webkit-print-color-adjust: exact;
  }

  .mat-list-textblock {
    page-break-inside: avoid;
  }

  .container .textblock-text {
    ul,
    li,
    ul li {
      list-style: disc outside !important;
    }
  }

  ul,
  li,
  p {
    page-break-before: auto;
    page-break-after: auto;
    page-break-inside: avoid;
    display: block;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    page-break-after: avoid;
    page-break-inside: avoid;
  }

  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }

  h2 {
    margin: 16px 0 8px !important;
  }

  .mh-sources {
    margin: 0;
  }

  .media-header,
  .mat-list-item {
    margin: 0 !important;
  }

  .header-website-title {
    margin: 0 !important;
    padding: 0 !important;
    width: 100% !important;
  }

  .print {
    display: flex;
    flex-direction: column-reverse;

    .textblock-text,
    .col-md-6 {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }

  .mat-card-title,
  .widget-header-text {
    font-size: 22.5pt;
    color: $zwart-1000 !important;
    line-height: 24pt;
  }

  .mat-list-title {
    color: $zwart-1000 !important;
  }

  .media-block {
    margin-top: 40px !important;
  }
}

.widget-store.inactive {
  .widget-store-btn {
    color: $zwart-100;
    pointer-events: none;
  }
}

.search-result-table tr,
.search-result-table tr td {
  max-height: 76px !important;

  .mat-list-base {
    padding-top: 0;
  }
}

.search-content .mat-paginator-navigation-last {
  display: none !important;
}

.search-bar .mat-form-field-underline > span {
  background: transparent !important;
}

.footer {
  height: 150px;
  color: map-get($mat-streappblauw, 1000);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  padding: 30px 0;
  gap: 30px;

  span {
    font-size: 22px;
    line-height: 32px;
  }

  .footer-logo {
    align-self: center;
  }

  .socials {
    display: flex;
    flex-direction: column;
    align-items: center;

    span {
      width: fit-content;
    }

    .social-container {
      display: flex;
      grid-column-gap: 10px;
      line-height: 0;
      padding: 5px 15px;
    }
  }

  .legal {
    display: flex;
    flex-direction: column;

    a:link,
    a:visited,
    a:hover,
    a:active {
      color: map-get($mat-streappblauw, 500);
      font-weight: bold;
      text-decoration: none;
    }
  }
}

.overview-map-page {
  .menu-item {
    .mat-button-wrapper {
      display: flex;
      flex-direction: row;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.mat-list-iconblock {
  // Rapporteer button bij reacties
  .report-button {
    color: map-get($mat-streappblauw, 500) !important;

    mat-icon {
      margin-left: 5px;
    }
  }
}

// Custom azure media styling
.amp-default-skin .vjs-poster {
  background-color: black;
}

.amp-default-skin .vjs-text-track-display {
  display: none;
}

.cdk-overlay-pane {
  &.mat-tooltip-panel {
    .menu-tooltip {
      color: map-get($mat-streappblauw, 500);
      background-color: white;
      border: 2px solid map-get($mat-streappblauw, 500);
      border-radius: 3px;
      font-size: 12px;
      font-family: Arial, Helvetica, sans-serif;
      padding: 5px 10px;
      text-transform: none;
    }
  }
}

.noMoreEvents {
  width: 100%;
  display: flex;
  justify-content: center;

  .noMoreEventsLabel {
    padding: 50px 10px;
    font-size: 30px;
    line-height: 36px;
    color: $zwart-75;
  }
}

/* snackbar (pop-up) classes */
.snackbar-successful {
  background-color: #e6ffe6;
  color: #2d8f2d;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  max-width: 500px;
  margin: 20px auto;
  transition: all 0.3s ease;
}

.snackbar-successful:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.snackbar-successful i {
  margin-right: 12px;
  font-size: 20px;
  color: #2d8f2d;
}

.snackbar-successful button {
  color: #2d8f2d;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.snackbar-successful button:hover {
  background-color: #d2f7d2;
}

.snackbar-fail {
  background-color: #ffe6e6;
  color: #8f2d2d;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  font-family: Arial, sans-serif;
  font-size: 16px;
  font-weight: 500;
  max-width: 500px;
  margin: 20px auto;
  transition: all 0.3s ease;
}

.snackbar-fail:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.snackbar-fail i {
  margin-right: 12px;
  font-size: 20px;
  color: #8f2d2d;
}

.snackbar-fail button {
  color: #8f2d2d;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.snackbar-fail button:hover {
  background-color: #f7d2d2;
}